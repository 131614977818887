// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap"
import './bootstrap_custom.js';

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import './add_jquery'


//= require jquery
//= require jquery_ujs